import React, { Component, Suspense } from 'react';
import { withRouter } from 'react-router-dom';

// Scroll up button
import ScrollUpButton from "react-scroll-up-button";

//Import Switcher
import ThemeSwitcher from "./ThemeSwitcher";
// import Zendesk from "../../../src/ZendDeskConfig";


//Import Icons
import FeatherIcon from 'feather-icons-react';

// Layout Components
const Topbar = React.lazy(() => import('./Topbar'));
const NavbarPage = React.lazy(() => import('../../pages/Saas Onepage/NavbarPage'));
const Footer = React.lazy(() => import('./Footer'));
const FooterWithoutMenuLightSocialOnly = React.lazy(() => import('./FooterWithoutMenuLightSocialOnly'));

const CustomDot = () => {
  return (
    <i><FeatherIcon icon="arrow-up" className="icons" /></i>
  );
};



class Layout extends Component {

  render() {
    return (
      <React.Fragment>
        
        {
          this.props.location.pathname === "/index-onepage" ?
          <NavbarPage/>
          :
          <Topbar />
        }
        
        {this.props.children}
        {(() => {
          if (this.props.location.pathname === "/index-marketing" || this.props.location.pathname === "/index-digital-agency" || this.props.location.pathname === "/index-modern-business" || this.props.location.pathname === "/index-services" || this.props.location.pathname === "/index-job") {
            return (
              <Footer isLight={true} />
            )
          }
          else if (this.props.location.pathname === "/index-portfolio" || this.props.location.pathname === "/page-contact-two") {
            return (
              <FooterWithoutMenuLightSocialOnly class="border-0 bg-light text-dark" />
            )
          }
          else if (this.props.location.pathname === "/index-personal" || this.props.location.pathname === "/helpcenter-overview" || this.props.location.pathname === "/page-invoice") {
            return (
              <FooterWithoutMenuLightSocialOnly class="" />
            )
          } else {
            return (
              <Footer />
            )
          }
        })()}

        
        
      
        {/* <Zendesk  zendeskKey={process.env.REACT_APP_ZENDESK_KEY} /> */}


      </React.Fragment>
    );
  }
}

export default withRouter(Layout);
